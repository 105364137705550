import React from 'react';
import Img from 'gatsby-image';
import styles from './holiday-giveaway.module.scss';

export default ({ fluid }) => {
  return (
    <div className="columns is-centered">
      <div className={`${styles.container}`}>
        <div className={`${styles.contentbox}`}>
          <Img
            fluid={fluid}
            className={styles.aboutImageWrapper}
            alt="2021 Holiday Giveaways"
          />
          <p className={`content ${styles.opening}`}>
            In 2021, we did something extra for all our current Trustaff
            travelers to look forward to during the holiday season. Travelers
            who worked their guaranteed hours during the week of Thanksgiving,
            Christmas, or New Year’s, were automatically entered to win their
            choice of one of <b>three</b> awesome traveler prize packs!
          </p>
          <div className="columns">
            <div className="column is-one-third">
              <p className="content is-centered">WORK THE WEEK OF</p>
              <p className={`content is-centered ${styles.holiday}`}>
                Thanksgiving
              </p>
              <p className={`content is-centered ${styles.winners}`}>
                10 winners announced on 12/1
              </p>
            </div>
            <div className="column is-one-third">
              <p className="content is-centered">WORK THE WEEK OF</p>
              <p className={`content is-centered ${styles.holiday}`}>
                Christmas
              </p>
              <p className={`content is-centered ${styles.winners}`}>
                10 winners announced on 12/29
              </p>
            </div>
            <div className="column is-one-third">
              <p className="content is-centered">WORK THE WEEK OF</p>
              <p className={`content is-centered ${styles.holiday}`}>
                New Year's
              </p>
              <p className={`content is-centered ${styles.winners}`}>
                10 winners announced on 1/5
              </p>
            </div>
          </div>

          <h2 className={styles.subheader}>
            30 total Trustaff travelers won the prize pack of their choice:
          </h2>

          <div className="columns">
            <div className={`column is-one-third ${styles.yeti}`}>
              <p className={`content is-centered ${styles.prizes}`}>
                Includes YETI Hopper Flip 12-can and YETI Rambler 26oz water
                bottle
              </p>
            </div>
            <div className={`column is-one-third ${styles.audio}`}>
              <p className={`content is-centered ${styles.prizes}`}>
                Includes Apple Airpod Pros and JBL Bluetooth speaker
              </p>
            </div>
            <div className={`column is-one-third ${styles.adventure}`}>
              <p className={`content is-centered ${styles.prizes}`}>
                Choose your own adventure with a $300 Amazon gift card
              </p>
            </div>
          </div>

          <h2 className={styles.subheader}>
            Congratulations to all this years' lucky winners.
          </h2>
          <p>
            All current Trustaff travelers who worked their guaranteed hours
            during one of the holiday weeks were automatically entered to win.
            Not sure if you qualified? Ask your recruiter for more details.
          </p>

          <div className={`section ${styles.icons}`}>
            <p className={`content ${styles.restrictions}`}>
              *Must be a Trustaff employee with a contract active through
              1/1/2022 and have worked 35+ hours during the holiday week to win.
              Winners announced on Facebook and notified by email. Gifts must be
              claimed within 30 days. Specific item model and/or colors may vary
              based on availability.Additional restrictions may apply, ask your
              recruiter for details.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
